<template>
  <v-dialog v-model="dialog" max-width="620">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="d-flex justify-center">
        <slot></slot>
      </div>
    </template>
    <v-card>
      <v-toolbar dense>
        <v-toolbar-title>Image Preview</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-img :src="resourceUrl(src)" contain max-height="320" class="grey darken-2"></v-img>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.stop="dialog = !dialog" outlined>Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    src: String
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>