<template>
  <div>
    <page-toolbar :title="rmpData.header.no_">
    </page-toolbar>
    <v-container>
      <page-title :title="rmpData.header.no_">
        <template slot="action">
          <v-btn class="ml-n1 mx-1" @click="$router.go(-1)">Back</v-btn>
          <v-btn class="mx-1" @click="getDetail(true)" color="primary">Refresh</v-btn>
        </template>
      </page-title>

      <v-row>
        <v-col md="12">
          <!-- General -->
          <card-expansion title="General" class="mb-3" :disabled="!editPermission">
            <v-row>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field dense v-model="rmpData.header.no_" placeholder="No." label="No." readonly filled outlined></v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field dense v-model="rmpData.header.document_date" placeholder="Doc. Date" label="Doc. Date" readonly outlined></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field dense v-model="rmpData.header.campaign_no_" placeholder="Campaign No." label="Campaign No." readonly outlined filled></v-text-field>
                <v-text-field dense v-model="rmpData.header.campaign_description" placeholder="Campaign Description" label="Campaign Description" readonly outlined filled></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field dense v-model="rmpData.header.customer_no_" placeholder="Customer" label="Customer" readonly outlined filled></v-text-field>
                <v-text-field dense outlined single-line readonly filled label="Customer Name" placeholder="Customer Name" v-model="rmpData.header.customer_name"></v-text-field>
              </v-col>
            </v-row>
          </card-expansion>

          <!-- Items -->
          <card-expansion title="Items" class="mb-3">
            <v-list three-line v-if="isMobile">
              <template v-for="(item, index) in rmpData.detail">
                <v-divider v-if="index > 0" :key="index"></v-divider>

                <v-list-item :key="item.item_no_">
                  <ImageItem :src="resourceUrl(item.item_image)" class="mx-auto">
                    <v-img :src="resourceUrl(item.item_image)" width="60"></v-img>
                  </ImageItem>

                  <v-list-item-content class="pl-2">
                    <v-list-item-title v-html="item.item_no_"></v-list-item-title>
                    <v-list-item-subtitle v-html="item.item_description"></v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <span>RM: {{ item.rm_criteria }}</span> |
                      <span>Qty: {{ numberFormat(item.quantity, 2) }}</span> |
                      <span>W: {{ numberFormat(item.weight, 2) }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
            <v-row v-if="!isMobile">
              <v-col>
                <div class="div-items">

                  <v-data-table :headers="rmpDetailColumns" :items="rmpData.detail" :hide-default-header="isMobile">
                    <template v-slot:[`item.item_image`]="{ item }">
                      <ImageItem :src="resourceUrl(item.item_image)" class="mx-auto">
                        <v-img :src="resourceUrl(item.item_image)" width="60"></v-img>
                      </ImageItem>
                    </template>
                    <template v-slot:[`item.item_description`]="{ item }">
                      <span>{{ item.item_description }}</span>
                    </template>
                    <template v-slot:[`item.weight`]="{ item }">
                      <span>{{ numberFormat(item.weight, 2) }}</span>
                    </template>
                    <template v-slot:[`item.quantity`]="{ item }">
                      <span>{{ numberFormat(item.quantity, 2) }}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="editItem(item)" color="primary">mdi-pencil</v-icon>
                      <v-icon small @click="deleteItem(item)" color="error">mdi-delete</v-icon>
                    </template>
                  </v-data-table>
                </div>
              </v-col>
            </v-row>
          </card-expansion>
          <!-- Loading & Shipment -->
          <card-expansion title="Loading & Shipment" class="mb-3" :disabled="!editPermission">
            <v-row>
              <v-col md="6">
                <v-text-field dense v-model="rmpData.header.nopol" placeholder="Nomor Polisi" label="Nomor Polisi" outlined></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-text-field dense v-model="rmpData.header.loading_date_start" label="Start Loading" placeholder="Start Loading" outlined readonly></v-text-field>
                <v-text-field dense v-model="rmpData.header.loading_date_end" label="End Loading" placeholder="End Loading" outlined readonly></v-text-field>
              </v-col>
              <v-col md="6">
                <v-text-field dense v-model="rmpData.header.est_shipment_date_start" label="Estimate Shpt Date Start" placeholder="Estimate Shpt Date Start" outlined readonly></v-text-field>
                <v-text-field dense v-model="rmpData.header.est_shipment_date_end" label="Estimate Shpt Date End" placeholder="Estimate Shpt Date End" outlined readonly></v-text-field>
              </v-col>
            </v-row>
          </card-expansion>

          <!-- Attachments -->
          <card-expansion title="Attachments" class="mb-3">
            <v-card color="transparent" elevation="0" :disabled="!editPermission" class="pa-0">
              <v-list-item two-line dense class="px-0">
                <v-list-item-content>
                  <v-list-item-title>Attachment 1</v-list-item-title>
                  <v-list-item-subtitle v-if="typeof rmpData.header.attachment1 != 'undefined' && rmpData.header.attachment1 != ''">
                    <a href="javascript:;" @click="goToExternalURL(rmpData.header.attachment1)">
                      <v-icon>mdi-link</v-icon>
                      {{ rmpData.header.attachment1 }}
                    </a>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item two-line dense class="px-0">
                <v-list-item-content>
                  <v-list-item-title>Attachment 2</v-list-item-title>
                  <v-list-item-subtitle v-if="typeof rmpData.header.attachment2 != 'undefined' && rmpData.header.attachment2 != ''">
                    <a href="javascript:;" @click="goToExternalURL(rmpData.header.attachment2)">
                      <v-icon>mdi-link</v-icon>
                      {{ rmpData.header.attachment2 }}
                    </a>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item two-line dense class="px-0">
                <v-list-item-content>
                  <v-list-item-title>Attachment 3</v-list-item-title>
                  <v-list-item-subtitle v-if="typeof rmpData.header.attachment3 != 'undefined' && rmpData.header.attachment3 != ''">
                    <a href="javascript:;" @click="goToExternalURL(rmpData.header.attachment3)">
                      <v-icon>mdi-link</v-icon>
                      {{ rmpData.header.attachment3 }}
                    </a>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item two-line dense class="px-0">
                <v-list-item-content>
                  <v-list-item-title>Attachment 4</v-list-item-title>
                  <v-list-item-subtitle v-if="typeof rmpData.header.attachment4 != 'undefined' && rmpData.header.attachment4 != ''">
                    <a href="javascript:;" @click="goToExternalURL(rmpData.header.attachment4)">
                      <v-icon>mdi-link</v-icon>
                      {{ rmpData.header.attachment4 }}
                    </a>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </card-expansion>
        </v-col>
      </v-row>


      <div class="d-flex flex-row flex-row-reverse">
        <v-btn class="mx-1" @click="saveData()" :disabled="!editPermission" color="primary">Save</v-btn>
        <v-btn class="ml-n1 mx-1" @click="$router.go(-1)">Back</v-btn>
      </div>


      <v-dialog v-model="viewImgItem_dialog" transition="dialog-bottom-transition" max-width="400">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>Image Preview</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="viewImgItem_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-img :src="resourceUrl(viewImgItem_src)" contain max-height="320"></v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.stop="viewImgItem_dialog = !viewImgItem_dialog">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import ImageItem from '../rmp/ImageItem.vue'
export default {
  components: {
    ImageItem,
  },

  data() {
    return {
      modal1: null,
      modal2: null,
      modal3: null,
      modal4: null,
      modal5: null,
      modal6: null,
      modal7: null,
      modal8: null,
      modal9: null,
      modal10: null,

      rmpData: {
        header: {},
        detail: [],
      },
      rmpDetailColumns: [
        { text: "Image", value: "item_image", sortable: false },
        { text: "No.", value: "item_no_" },
        { text: "Description", value: "item_description" },
        { text: "RM Criteria", value: "rm_criteria" },
        { text: "UoM", value: "uom" },
        { text: "Weight", value: "weight", align: "end" },
        { text: "Quantity", value: "quantity", align: "end" },
      ],

      viewImgItem_dialog: null,
      viewImgItem_src: null,

      tmpEdit: {
        dialog: false,
        data: {},
      },
      tmpDelete: {
        dialog: false,
        data: {},
      },
      items: [],
      fileupload: null,
      n: null,

      customers: [],
      selectedCustomer: {
        customer_no_: "",
        customer_name: "",
      },

      binContents: [],
      tmpAdd: {
        title: "Add New Item",
        loading: null,
        loading2: false,
        dialog: false,
        itemImg: null,
        selectedItem: {},
        selectedRmCriteria: '',
        quantity: null,
        valid: null,
      },
    };
  },

  computed: {
    editPermission() {
      if (typeof this.rmpData.header.status === "undefined") return false;
      if (this.rmpData.header.status == "open") return true;
      return false;
    },
  },
  watch: {
    // whenever question changes, this function will run
    // eslint-disable-next-line no-unused-vars
    selectedCustomer(n, o) {
      if (typeof n !== "undefined") {
        this.rmpData.header.customer_no_ = n.customer_no_;
        this.rmpData.header.customer_name = n.customer_name;
      }
    },
  },
  methods: {
    async getDetail(refresh) {
      if (refresh == true) this.AxiosStorageRemove("GET", "rmp/data-detail");

      var id = this.$route.query.id;
      this.showLoadingOverlay(true);
      var params = {
        id: id,
      };
      var config = {
        params: params,
        cacheConfig: true,
      };
      await this.$axios
        .get("rmp/data-detail", config)
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.rmpData = resData.data;
            // console.log(resData.data.header.campaign_no_);

            this.selectedCustomer.customer_no_ =
              resData.data.header.customer_no_;
            this.selectedCustomer.customer_name =
              resData.data.header.customer_name;

            // console.log(resData.data.detail);

            this.getCustomers(resData.data.header.campaign_no_);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },

  mounted() {
    this.requiredLogin();
    this.modulePermission('rmp', 'view', true)
    this.getDetail();
  },
};
</script>

<style lang="scss">
.div-items .v-data-table>.v-data-table__wrapper .v-data-table__mobile-row {
  height: initial;
  min-height: 22px !important;
  border-top: none;
}

.div-items .v-data-table__mobile-table-row {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>